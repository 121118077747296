import React from "react"

export default () => {

  const form = React.createRef();
  const [message, setMessage] = React.useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch("/", {
      method: "POST",
      body: new FormData(form.current)
    })
      .then(() => setMessage('Envoyé!'))
      .catch(() => setMessage('Oups! Essayez plus tard.'));
  }

  return (
    <div id="contact" className="contact">

      <div className="container">

        <div className="spotify-container">
          <iframe src="https://open.spotify.com/follow/1/?uri=spotify:artist:5qK9vcHiC1JgnFizxPLelt&size=detail&theme=light" width={300} height={56} scrolling="no" frameBorder="0" style={{ border: "none", overflow: "hidden" }} allowtransparency="true"></iframe>
        </div>

        <div className="box contact-grids">
          <div>
            <h2>Une question?</h2>
            <p>Booking, questions, commentaires ou informations?</p>
            <form name="contact" method="POST" data-netlify="true" data-netlify-honeypot="honeypot" onSubmit={handleSubmit} ref={form}>
              <div style={{ display: 'none' }}>
                  <label htmlFor="honeypot">Keep this field blank</label>
                  <input type="text" name="honeypot" id="honeypot" />
              </div>
              <input type="hidden" name="form-name" value="contact" />
              <input type="text" name="name" placeholder="Nom"></input>
              <input type="email" name="email" placeholder="Adresse courriel"></input>
              <textarea name="message" placeholder="Message" />
              <span>{message}</span>
              <button type="submit">Envoyer</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}