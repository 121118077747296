import React from "react"
import { Controller, Scene } from 'react-scrollmagic';
import { Tween } from 'react-gsap';

import newsletterImage from '../images/newsletter-image.svg'
import newsletterImageBottom from '../images/newsletter-image-bottom.svg'
import orderNowBg from '../images/order-now-bg.svg'
import orderNowCD from '../images/order-now-cd.png'

export default () => {

    return (
        <div className="order-now">
            <Controller>
                <Scene
                    duration={1000}
                    reverse={true}
                    offset={-250}
                >
                    <Tween
                        duration={5}
                        from={{
                            y: 250
                        }}
                        to={{
                            y: -100
                        }}
                    >
                        <img src={orderNowBg} alt="Commander maintenant background" className="background" />
                    </Tween>
                </Scene>
            </Controller>
            <Controller>
                <Scene
                    duration={900}
                    reverse={true}
                    offset={0}
                >
                    <Tween
                        duration={5}
                        ease="easeInOut"
                        from={{
                            x: '10%'
                        }}
                        to={{
                            x: '-50%'
                        }}
                    >
                        <img src={newsletterImage} alt="Commander maintenant image" className="image" />
                    </Tween>
                </Scene>
            </Controller>
            <img src={newsletterImageBottom} alt="Commander maintenant background 2" className="image-bottom" />
            <div className="box">
                <img src={orderNowCD} alt="Pochette Papa Rocker" className="image-cd" />
                <div>
                    <h2>Commander maintenant!</h2>
                    <h3>votre copie de l'album</h3>
                    <button onClick={() => window.open('https://bit.ly/2I3cSp2')} className="btn">Commander</button>
                </div>
            </div>
        </div>
    )
}
