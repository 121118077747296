import React from "react"
import { Controller, Scene } from 'react-scrollmagic';
import { Tween } from 'react-gsap';

import headerSky from '../images/header-sky.svg'
import hill from '../images/hill.svg'
import logo from '../images/logo.svg'
import cloud1 from '../images/cloud1.svg'
import cloud2 from '../images/cloud2.svg'
import cloud3 from '../images/cloud3.svg'
import castle from '../images/castle.svg'
import horse from '../images/horse.svg'
import iceCream from '../images/ice-cream.svg'
import mathieu from '../images/mathieu.png'

export default () => {

  return (
    <div className="hero-header">
      <Tween
        duration={20}
        repeat={20}
        yoyo={true}
        from={{
          scaleX: 1,
        }}
        to={{
          scaleX: 1.5,
        }}
      >
        <img src={headerSky} className="sky" alt="Sky" />
      </Tween>

      <Tween
        duration={50}
        repeat={20}
        yoyo={true}
        from={{
          xPercent: 0,
        }}
        to={{
          xPercent: -100,
        }}
      >
        <div className="cloud1">
          <Controller>
            <Scene
              duration={400}
              reverse={true}
              offset={150}
            >
              <Tween
                duration={5}
                to={{
                  y: '-50px'
                }}
              >
                <img src={cloud1} alt="cloud1" />
              </Tween>
            </Scene>
          </Controller>
        </div>
      </Tween>

      <Tween
        duration={50}
        repeat={20}
        yoyo={true}
        from={{
          xPercent: 0,
        }}
        to={{
          xPercent: -250,
        }}
      >
        <div className="cloud2">
          <Controller>
            <Scene
              duration={400}
              reverse={true}
              offset={350}
            >
              <Tween
                duration={5}
                to={{
                  y: '50px'
                }}
              >
                <img src={cloud2} alt="cloud2" />
              </Tween>
            </Scene>
          </Controller>
        </div>
      </Tween>

      <Tween
        duration={50}
        repeat={20}
        yoyo={true}
        from={{
          xPercent: 0,
        }}
        to={{
          xPercent: 100,
        }}
      >
        <div className="cloud3">
          <Controller>
            <Scene
              duration={1000}
              reverse={true}
              offset={350}
            >
              <Tween
                duration={5}
                to={{
                  y: '-125px'
                }}
              >
                <img src={cloud3} alt="cloud3" />
              </Tween>
            </Scene>
          </Controller>
        </div>
      </Tween>

      <Tween
        delay={0.3}
        duration={0.3}
        ease="easeInOut"
        from={{
          yPercent: 250
        }}
        to={{
          yPercent: 0
        }}
      >
        <div className="castle">
          <Controller>
            <Scene
              duration={1000}
              reverse={true}
              offset={350}
            >
              <Tween
                duration={5}
                to={{
                  y: '125px'
                }}
              >
                <img src={castle} alt="castle" />
              </Tween>
            </Scene>
          </Controller>
        </div>
        <img src={hill} alt="hill" className="hill" />
        <img src={horse} className="horse" alt="horse" />
        <img src={iceCream} className="iceCream" alt="iceCream" />
        <img src={mathieu} className="mathieu" alt="mathieu" />
      </Tween>

      <Tween
        delay={1.5}
        offset={1.5}
        duration={1.2}
        ease="easeInOut"
        from={{
          yPercent: 105,
          scaleX: 0,
          scaleY: 0
        }}
        to={{
          yPercent: -20,
          scaleX: 0.35,
          scaleY: 0.35
        }}
      >
        <div className="logo">
          <Controller>
            <Scene
              duration={400}
              reverse={true}
              offset={350}
            >
              <Tween
                duration={3}
                to={{
                  y: '125px'
                }}
              >
                <img src={logo} alt="logo" />
              </Tween>
            </Scene>
          </Controller>
        </div>
      </Tween>
    </div>
  )
}