import React from "react"
import { Controller, Scene } from 'react-scrollmagic';
import { Tween } from 'react-gsap';
import PostLink from "../components/post-link"

import NewsBG from '../images/news-bg.svg'
import Turkey from '../images/news-turkey.svg'

export default ({ edges }) => {

  const News = edges
    .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map(edge => <PostLink key={edge.node.id} post={edge.node} />)
    
  return (
    <div id="news-section" className="news-section">
      <Controller>
        <Scene
          duration={2000}
          reverse={true}
          offset={-250}
        >
          <Tween
            duration={10}
            from={{
              y: 250
            }}
            to={{
              y: -100
            }}
          >
            <img src={NewsBG} alt="music player background" className="background1" />
          </Tween>
        </Scene>
        <Scene
          duration={2000}
          reverse={true}
          offset={-650}
        >
          <Tween
            duration={2}
            from={{
              y: 450,
              x: 400
            }}
            to={{
              y: -1000,
              x: -400
            }}
          >
            <img src={Turkey} alt="music player background" className="background2" />
          </Tween>
        </Scene>
      </Controller>
      <div className="container">
        <h2>Nouvelles</h2>
        <div className="grids">
          {News}
        </div>
      </div>
    </div>
  )
}