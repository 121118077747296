import React from "react"
import { Controller, Scene } from 'react-scrollmagic';
import { Tween } from 'react-gsap';

import newsletterBg from '../images/newsletter-bg.svg'

export default () => {

  return (
    <div className="newsletter">
      <Controller>
        <Scene
          duration={1000}
          reverse={true}
          offset={-150}
        >
          <Tween
            duration={5}
            from={{
              y: 250
            }}
            to={{
              y: -100
            }}
          >
            <img src={newsletterBg} alt="Background infolettre" className="background" />
          </Tween>
        </Scene>
      </Controller>
      <div className="box">
        <h2>Infolettre</h2>
        <p>Inscris-toi pour être au courant des dernières nouvelles!</p>
        <form action="https://app.convertkit.com/forms/1687952/subscriptions" method="post" data-sv-form="1687952" data-uid="37d039c210" data-format="inline" data-version="5" _lpchecked="1">
          <input type="text" aria-label="Your first name" name="fields[first_name]" placeholder="Nom"></input>
          <input type="text" name="email_address" aria-label="Your email address" placeholder="Courriel" required=""></input>
          <button data-element="submit">S'abonner</button>
        </form>
      </div>
    </div>
  )
}