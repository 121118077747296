import React from "react"
import { Controller, Scene } from 'react-scrollmagic';
import { Tween } from 'react-gsap';

import CarBG from '../images/car-bg.svg'
import Smoke from '../images/smoke.svg'
import Car from '../images/car.png'

export default () => {

  let width = 0;
    try {
        width = window.innerWidth;
    } catch(e){

    }

  return (
    <div className="car-section">
      <Controller>
        <Scene
          duration={2000}
          reverse={true}
          offset={-150}
        >
          <Tween
            duration={10}
            from={{
              y: 250
            }}
            to={{
              y: -100
            }}
          >
            <img src={CarBG} alt="music player background" className="background1" />
          </Tween>
        </Scene>
        <img src={Smoke} alt="music player background" className="background2" />
        <Scene
          duration={1000}
          reverse={true}
          offset={width > 1023 ? -650 : -200}
        >
          <Tween
            duration={1}
            from={{
              x: 0
            }}
            to={{
              x: 800
            }}
          >
            <img src={Car} alt="music player background" className="car" />
          </Tween>
        </Scene>
      </Controller>
    </div>
  )
}