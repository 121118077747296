import React from "react"
import Helmet from 'react-helmet';
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import HeroHeader from "../components/heroHeader"
import OrderNow from "../components/ordernow"
import Newsletter2 from "../components/newsletter2"
import MusicPlayer from "../components/musicPlayer"
import YoutubePlayer from "../components/youtubePlayer"
import Car from "../components/car"
import News from "../components/News"
import Bio from "../components/Bio"
import Contact from "../components/Contact"
import Footer from "../components/footer"

const IndexPage = ({
  data: {
    siteMetadata,
    news,
    tracks,
    videos
  },
}) => {
  return (
    <Layout>
      <Helmet>
        <title>{siteMetadata.siteMetadata.title}</title>
        <meta name="description" content={siteMetadata.siteMetadata.description} />
      </Helmet>
      <HeroHeader/>
      <OrderNow />
      <MusicPlayer edges={tracks.edges}/>
      <Newsletter2/>
      <YoutubePlayer edges={videos.edges}/>
      <Car />
      <News edges={news.edges} />
      <Bio />
      <Contact />
      <Footer />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    news: allMarkdownRemark(filter: {frontmatter: {template: {eq: "NewsPost"}}}, sort: { order: DESC, fields: [frontmatter___date]}, limit: 4) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            thumbnail
          }
        }
      }
    }
    tracks: allMarkdownRemark(filter: {frontmatter: {template: {eq: "Tracks"}}}, sort: { order: DESC, fields: [frontmatter___date]}) {
      edges {
        node {
          id
          frontmatter {
            path
            title
            album
            order
          }
        }
      }
    }
    videos: allMarkdownRemark(filter: {frontmatter: {template: {eq: "Videos"}}}, sort: { order: DESC, fields: [frontmatter___date]}) {
      edges {
        node {
          id
          frontmatter {
            id
            title
            thumbnail
            order
          }
        }
      }
    }
    siteMetadata: site {
      siteMetadata {
        title
        description
      }
    }
  }
`
