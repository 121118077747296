import React from "react"
import { Controller, Scene } from 'react-scrollmagic';
import { Tween } from 'react-gsap';
import FooterBG from '../images/footer-bg.svg'
import FooterBackWave from '../images/footer-back-wave.svg'
import FooterMiddleWave from '../images/footer-middle-wave.svg'
import FooterFrontWave from '../images/footer-front-wave.svg'
import FooterBoat from '../images/footer-boat.svg'
import FooterSand from '../images/footer-sand.svg'
import FooterCrabs from '../images/footer-crabs.svg'
import Facebook from '../images/facebook.svg'
import Instagram from '../images/instagram.svg'
import Spotify from '../images/spotify.svg'
import Youtube from '../images/youtube.svg'
import Apple from '../images/apple.svg'

export default () => {
    
    let width = 0;
    try {
        width = window.innerWidth;
    } catch(e){

    }

    return (
        <div className="footer-section">
            {width > 1023 ?
                <Controller>
                    <Scene
                        duration={2000}
                        reverse={true}
                        offset={-250}
                    >
                        <Tween
                            duration={10}
                            from={{
                                y: 250
                            }}
                            to={{
                                y: -100
                            }}
                        >
                            <img src={FooterBG} alt="music player background" className="background1" />
                        </Tween>
                    </Scene>

                    <Tween
                        repeat={99999}
                        yoyo={true}
                        duration={4}
                        ease="easeInOut"
                        from={{
                            x: 100,
                        }}
                        to={{
                            x: 150,
                        }}
                    >
                        <img src={FooterBackWave} alt="music player background" className="background2" />
                    </Tween>

                    <Tween
                        repeat={99999}
                        yoyo={true}
                        duration={3}
                        ease="easeInOut"
                        from={{
                            x: 100,
                        }}
                        to={{
                            x: 0,
                        }}
                    >
                        <img src={FooterMiddleWave} alt="music player background" className="background3" />
                    </Tween>

                    <Tween
                        repeat={99999}
                        yoyo={true}
                        duration={4}
                        ease="easeInOut"
                        from={{
                            x: 50
                        }}
                        to={{
                            x: 0
                        }}
                    >
                        <img src={FooterFrontWave} alt="music player background" className="background4" />
                    </Tween>

                    <Tween
                        repeat={99999}
                        yoyo={true}
                        duration={3}
                        ease="easeInOut"
                        from={{
                            y: 0,
                            x: 0,
                            rotate: -10
                        }}
                        to={{
                            y: 25,
                            x: 25,
                            rotate: 25
                        }}
                    >
                        <img src={FooterBoat} alt="music player background" className="background5" />
                    </Tween>

                    <img src={FooterSand} alt="music player background" className="background6" />

                    <Scene
                        duration={2000}
                        reverse={true}
                        offset={-600}
                    >
                        <Tween
                            duration={1}
                            from={{
                                x: 800
                            }}
                            to={{
                                x: -300
                            }}
                        >
                            <img src={FooterCrabs} alt="music player background" className="crabs" />
                        </Tween>
                    </Scene>
                </Controller>
            : null
            }
            <div className="container">
                <h4>Inscris-toi pour être au courant des dernières nouvelles!</h4>
                <form action="https://app.convertkit.com/forms/1687952/subscriptions" method="post" data-sv-form="1687952" data-uid="37d039c210" data-format="inline" data-version="5" _lpchecked="1">              
                    <input type="text" aria-label="Your first name" name="fields[first_name]" placeholder="Nom"></input>
                    <input type="text" name="email_address" aria-label="Your email address" placeholder="Courriel" required=""></input>
                    <button data-element="submit">S'abonner</button>
                </form>
                <div className="social-medias">
                    <a href="https://www.facebook.com/PrimaPapaRocker" title="Facebook" target="_blank"><img src={Facebook} alt="Facebook" /></a>
                    <a href="https://www.instagram.com/primapaparocker/?hl=fr-ca" title="Instagram" target="_blank"><img src={Instagram} alt="Instagram" /></a>
                    <a href="https://open.spotify.com/artist/5qK9vcHiC1JgnFizxPLelt" title="Spotify" target="_blank"><img src={Spotify} alt="Spotify" /></a>
                    <a href="https://www.youtube.com/playlist?reload=9&list=PLCQTwA8T16T4PDsjevAClPIwZYzByDRfd" title="Youtube" target="_blank"><img src={Youtube} alt="Youtube" /></a>
                    <a href="https://music.apple.com/fr/artist/papa-rocker/id1466284109" title="Apple" target="_blank"><img src={Apple} alt="Apple" /></a>
                </div>
            </div>
        </div>
    )
}