import React from "react"
import { Controller, Scene } from 'react-scrollmagic';
import { Tween } from 'react-gsap';

import BioBG from '../images/bio-bg.svg'
import BioArt from '../images/bio-art.svg'
import BioPhoto from '../images/bio-photo.png'
import BioIllus from '../images/bio-illus.png'
import Signature from '../images/signature.svg'

export default () => {
  return (
    <div id="bio-section" className="bio-section">
      <Controller>
        <Scene
          duration={2000}
          reverse={true}
          offset={-250}
        >
          <Tween
            duration={10}
            from={{
              y: 250
            }}
            to={{
              y: -100
            }}
          >
            <img src={BioBG} alt="bio background" className="background1" />
          </Tween>
        </Scene>
        <Scene
          duration={3000}
          reverse={true}
          offset={-250}
        >
          <Tween
            duration={10}
            from={{
              y: -500
            }}
            to={{
              y: 100
            }}
          >
            <img src={BioArt} alt="bio background" className="background2" />
          </Tween>
        </Scene>
      </Controller>
      <div className="columns">
        <div className="images">
          <img src={BioPhoto} alt="bio background" className="bio-photo" />
        </div>
        <div className="container">
          <h2>Bio</h2>
          <p>La vie de Mathieu Tremblay bascula dramatiquement lors d'un banal souper en famille. En asseyant sa fille dans une chaise haute, il aperçoit ces 3 mots magiques : PRIMA - PAPA - ROCKER. Il n'en faut pas plus pour lui inspirer un concept encore jamais vu - du Rock’N’Roll à saveur familiale, teinté d'un humour délirant : Papa Rocker était né.</p>

          <p>Très présent sur les réseaux sociaux où il se fait connaître avec ses vidéoclips aux concepts farfelus, il accumule les millions de visionnements depuis la sortie de son premier single. Son album éponyme "Papa Rocker" paraît le 4 décembre 2020; un mélange parfait entre le rock des 70s, une Fender Stratocaster… et un gros gâteau au chocolat. </p>

          <p>Les thèmes abordés? Demandez à Papa Rocker lui-même : « Pourquoi chanter une peine d'amour quand on peut mettre en musique les réalités quotidiennes? Premières rencontres, amour inconditionnel, bataille contre les enfants pour finir les dernières bouchées du souper et matins difficiles sont au rendez-vous »!</p>

          <p>Des rythmes qui ne laissent pas le choix de danser, une vibe qui rappelle les grands bands du rock’n’roll et un grand sourire au travers de la boucane créée par ses riffs incendiaires, Papa Rocker à l’objectif bien avoué de ramener le Rock dans nos vies. « Du rock pour toute la famille, conçu pour ceux qui aiment la vie et qui n'ont pas peur de dire des conneries… bienvenue dans mon monde! »</p>

          <p><img src={Signature} alt="Signature" className="signature" /></p>

        </div>
        <img src={BioIllus} alt="bio background" className="background6" />
      </div>
    </div>
  )
}