import React from "react"
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';

import musicPlayerBG from '../images/music-player-bg.svg'
import pochette from '../images/pochette.jpg'
import SpotifyButton from '../images/spotify-btn.svg'
import Play from '../images/play.svg'
import Pause from '../images/pause.svg'
import AudioPlayer from 'react-h5-audio-player';

export default ({ edges }) => {

  const TracksData = edges.map(edge => edge.node.frontmatter)

  const player = React.createRef();
  const [inProgress, setInProgress] = React.useState(false);
  const [list, setList] = React.useState([])
  const [playlistTrack, setPlaylistTrack] = React.useState(-1)
  const [SortedTrackData, setSortedTrackData] = React.useState([])

  React.useEffect(() => {
    setSortedTrackData(TracksData.sort((a, b) => Number(a.order) - Number(b.order)))
    setList(TracksData.sort((a, b) => Number(a.order) - Number(b.order)).map(trackData => trackData.path))
  }, [])

  const updateTrack = (track) => {
    if(track > list.length - 1){
      setPlaylistTrack(0)
    } else{
      setPlaylistTrack(track)
    }
  }

  const pause = () => {
    player.current.audio.current.pause();
    setInProgress(false);
  }

  const playTrack = (track) => {
    setPlaylistTrack(track)
    player.current.audio.current.play();
    setInProgress(true);
  }

  return (
    <div className="music-player">
      <Controller>
        <Scene
          className="background1"
          duration={1000}
          reverse={true}
          offset={-150}
        >
          <Tween
            duration={10}
            from={{
              y: 250
            }}
            to={{
              y: -100
            }}
          >
            <img src={musicPlayerBG} alt="music player background" className="background2" />
          </Tween>
        </Scene>
      </Controller>

      <div className="player container">
        <div className="pochette">
          <img src={pochette} alt="pochette" />
        </div>
        <div className="playlist">
          <div className="header stagger">
            <div className="title">
              <h3>Papa Rocker</h3>
              <h4>Sortie le 4 décembre 2020</h4>
            </div>
            <div>
              <a href="https://open.spotify.com/artist/5qK9vcHiC1JgnFizxPLelt" className="btn"><img src={SpotifyButton} alt="Youtube" />Écouter sur Spotify</a>
            </div>
          </div>
          <div className="playlist-list">
            <Controller>
              <Timeline>
                <Scene
                  duration={500}
                >
                  {(progress) => (
                    <div>
                      <Tween
                        ease='Back.easeOut'
                        staggerFrom={{
                          y: -100,
                        }}
                        staggerTo={{
                          y: 50,
                        }}
                        stagger={0.15}
                        progress={progress}
                        pause
                      >
                        {SortedTrackData.map((track, index) => 
                          <div className="item stagger" key={index}>
                            {playlistTrack === index && inProgress ? <a className="pause-btn" onClick={() => pause(index)}><img src={Pause} alt="Pause" /></a> : <a className="play-btn" onClick={() => playTrack(index)}><img src={Play} alt="Play" /></a>}
                            <div className="title">
                              <h3>{track.title}</h3>
                            </div>
                            <div>
                              00:30
                            </div>
                          </div>
                        )}
                      </Tween>
                    </div>
                  )}
                </Scene>
              </Timeline>
            </Controller>

            <AudioPlayer
              ref={player}
              className="audio-player"
              src={list[playlistTrack]}
              onPlay={() => setInProgress(true)}
              onPause={() => setInProgress(false)}
              autoPlayAfterSrcChange
              showSkipControls
              showJumpControls={false}
              onClickPrevious={() => updateTrack(playlistTrack - 1)}
              onClickNext={() => updateTrack(playlistTrack + 1)}
            />

          </div>
        </div>
      </div>
    </div>
  )
}