import React from "react"
import { Controller, Scene } from 'react-scrollmagic';
import { Tween } from 'react-gsap';
import YouTube from 'react-youtube';

import youtubeBG from '../images/youtube-bg.svg'
import monster from '../images/monster.svg'
import YoutubeButton from '../images/youtube-btn.svg'
import AbonnerButton from '../images/abonner-button.png'

export default ({ edges }) => {

  const VideosData = edges.map(edge => edge.node.frontmatter)
  const Videos = edges.map(edge => edge.node.frontmatter.id)

  const opts = {
    height: '550',
    width: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  const [videos] = React.useState(Videos)
  const [playlistTrack, setPlaylistTrack] = React.useState(0)

  return (
    <div id="youtube-player" className="youtube-player">
      <Controller>
        <Scene
          duration={1000}
          reverse={true}
          offset={-150}
        >
          <Tween
            duration={10}
            from={{
              y: 250
            }}
            to={{
              y: -100
            }}
          >
            <img src={youtubeBG} alt="music player background" className="background1" />
          </Tween>
        </Scene>
      </Controller>

      <div className="player">
        <Controller>
          <Scene
            duration={1000}
            reverse={true}
            offset={-150}
          >
            <Tween
              duration={10}
              from={{
                y: 250
              }}
              to={{
                y: -100
              }}
            >
              <img src={monster} alt="monster" className="monster" />
            </Tween>
          </Scene>
        </Controller>
        <div className="screen">
          <YouTube videoId={videos[playlistTrack]} opts={opts} />
          <div className="header">
            <div className="title">
            </div>
            <div>
              <a className="btn"><img src={YoutubeButton} alt="Youtube" />S'abonner</a>
            </div>
          </div>
        </div>
        <div className="playlist">
          <div className="container">
            <div className="playlist-list">
              <Controller>
                <Scene
                  duration={500}
                >
                  {(progress) => (
                    <div>
                      <Tween
                        ease='Back.easeOut'
                        staggerFrom={{
                          y: -100,
                        }}
                        staggerTo={{
                          y: 50,
                          ease: 'Back.easeOut'
                        }}
                        stagger={0.15}
                        progress={progress}
                        pause
                      >
                        {VideosData.sort((a, b) => Number(a.order) - Number(b.order)).map((video, index) =>
                          <div className="item stagger" key={index}>
                            <a className="video-thumb" onClick={() => setPlaylistTrack(index)}>
                              <img src={video.thumbnail} title={video.title} />
                            </a>
                          </div>
                        )}
                      </Tween>
                    </div>
                  )}
                </Scene>
              </Controller>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}